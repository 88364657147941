<template>
  <div @scroll="isBottom" id="list-purchase">
    <h2 class="mb-5">Cash Before Delivery</h2>
    <div class="d-flex pr-0 justify-content-center align-items-center flex-column" v-if="!loading && data.length === 0">
      <div class="w-35 d-flex align-items-center flex-column">
        <div class="d-flex align-items-center">
          <EmptyWalletIcon />
        </div>
        <span style="color: #015CA1" class="ml-2 h4 mt-3">
          {{ $t('menu.purchase.cash_delivery.empty_transaction') }}
        </span>
        <div class="card-footer border-top-0">
          <button @click="goToHome" style="color: #015CA1; border: 1px solid #015289" class="btn rounded w-100">
            {{ $t('menu.purchase.cash_delivery.shopping_now') }}
          </button>
        </div>
      </div>
    </div>
    <div v-else id="wrap"
      v-for="({ image, bank_name, expired_at, method, total, order_id, account_number, order_number, payment_name }, key) in data"
      :key="key" class="col pr-0">
      <div class="card col-12 d-flex justify-content-center">
        <div class="card-header d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <BlueWalletIcon />
            </div>
            <div class="flex-column d-flex">
              <span style="color: #929292;">{{ $t('menu.purchase.cash_delivery.order_number') }}</span>
              <span>{{ order_number }}</span>
            </div>
          </div>
          <div class="flex-column d-flex text-right">
            <span style="color: #929292;">{{ $t('menu.purchase.cash_delivery.pay_before') }}</span>
            <span style="color: #FF0000;">{{ expired_at }}</span>
          </div>
        </div>
        <div class="card-body d-flex justify-content-between">
          <div class="d-flex">
            <div class="mr-2">
              <img v-if="image" :src="image" :alt="image" width="114.17px" />
            </div>
            <div class="d-flex flex-column">
              <span v-if="method.includes('transfer')">Bank Transfer</span>
              <span v-else-if="method.includes('va')">Virtual Account</span>
              <span v-else-if="method.includes('go_pay')">{{payment_name}}</span>
              <span v-else>COD</span>
              <span v-if="!method.includes('go_pay')" style="color: #929292;">{{ account_number }}</span>
            </div>
          </div>
          <div class="align-bottom d-flex align-items-end">
            <span class="mr-1">{{ $t('menu.purchase.cash_delivery.total_payment') }}</span>
            <span style="color: #F5831F;">Rp.{{ total }}</span>
          </div>
        </div>
        <div class="card-footer border-0 d-flex justify-content-end ">
          <button @click="goToHowToPay(order_id)" class="btn rounded">
            {{ $t('menu.purchase.cash_delivery.how_to_pay') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card border-0 shadow-none col-12 d-flex align-items-end">
      <button @click="$router.go(-1)" class="btn w-25">
        {{ $t('menu.purchase.cash_delivery.back') }}
      </button>
    </div>
  </div>
</template>

<script>
import BlueWalletIcon from '@/components/Icons/BlueWallet.vue'
import EmptyWalletIcon from '@/components/Icons/EmptyWallet.vue'
import moment from 'moment'

export default {
  name: 'waiting-payment',
  components: {
    BlueWalletIcon,
    EmptyWalletIcon,
  },
  data: function () {
    return {
      data: [],
      loading: false,
      page: 1,
      limit: 10,
      total: 0,
    }
  },
  methods: {
    getData() {
      this.loading = true
      this.$store.dispatch('getPurchaseWaitingPayment', {
        page: this.page,
        limit: this.limit,
      }).then(res => {
        this.data = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    getWaitingPayment() {
      this.loading = true
      this.$store.dispatch('purchase/GETWAITINGPAYMENT', {
        channel_id: this.$store.state.user.user_data.channel_id,
        buyer_id: this.$store.state.user.user_data.buyer_id,
        page: this.page,
      })
        .then(({ data }) => {
          this.total_waiting_payment = data.list.length
          const newData = data.list.map(({ expired_at, total, payments, ...rest }) => ({
            expired_at: moment(expired_at).format('Do MMMM YYYY, h:mm:ss'),
            total: Intl.NumberFormat('en-US').format(total),
            image: payments[0].image,
            method: payments[0].method,
            payment_name: payments[0].payment_name,
            account_number: payments[0].account_number,
            ...rest,
          }))
          this.data = [...this.data, ...newData]
          this.total = data.total_data
          this.loading = false
          this.page = this.page + 1
        })
        .catch(() => {
          this.loading = false
        })
    },
    goToHowToPay(id) {
      this.$router.push({ path: `/purchase/how-to-pay/${id}` })
    },
    isBottom() {
      const el = document.querySelectorAll('#wrap')
      const navbarHeight = 151
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        this.getWaitingPayment()
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.isBottom)
    this.getWaitingPayment()
  },
  created() {
    window.addEventListener('scroll', this.isBottom)
  },
  destroyed() {
    window.removeEventListener('scroll', this.isBottom)
  },
}
</script>
